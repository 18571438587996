import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import bearing from '../../images/ball-bearing.jpg'
import seals from '../../images/oil-seals.jpg'
import tool from '../../images/hand-tools.jpg'
import filters from '../../images/hydraulic-filter.jpg'
import fittings from '../../images/aluminium-couplings.jpg'
import hoses from '../../images/hydraulic-hoses.jpg'
import vbelt from '../../images/v-belts.jpg'
import oring from '../../images/o-rings.jpg'
import safety from '../../images/safety-items.jpg'
import { Link } from 'react-router-dom'
import './../../components/contact/contact.css'

import 'animate.css';
import TypingAnimation from '../../components/TypingAnimation'
function Home({ language, content }) {
  const canvasRef = useRef(null);
  const largeHeaderRef = useRef(null);

  const isRTL = language === 'ar'; // Check if language is Arabic
  useEffect(() => {
    let width, height, largeHeader, canvas, ctx, circles, target, animateHeader = true;

    const initHeader = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      target = { x: 0, y: height };

      largeHeader = largeHeaderRef.current;
      largeHeader.style.height = height + 'px';

      canvas = canvasRef.current;
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');

      // create particles
      circles = [];
      for (let x = 0; x < width * 0.5; x++) {
        const c = new Circle();
        circles.push(c);
      }
      animate();
    };

    const addListeners = () => {
      window.addEventListener('scroll', scrollCheck);
      window.addEventListener('resize', resize);
    };

    const scrollCheck = () => {
      animateHeader = document.body.scrollTop <= height;
    };

    const resize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      largeHeader.style.height = height + 'px';
      canvas.width = width;
      canvas.height = height;
    };

    const animate = () => {
      if (animateHeader) {
        ctx.clearRect(0, 0, width, height);
        for (let i in circles) {
          circles[i].draw();
        }
      }
      requestAnimationFrame(animate);
    };

    class Circle {
      constructor() {
        this.pos = {};
        this.init();
      }

      init() {
        this.pos.x = Math.random() * width;
        this.pos.y = height + Math.random() * 100;
        this.alpha = 0.1 + Math.random() * 0.3;
        this.scale = 0.1 + Math.random() * 0.3;
        this.velocity = Math.random();
      }

      draw() {
        if (this.alpha <= 0) {
          this.init();
        }
        this.pos.y -= this.velocity;
        this.alpha -= 0.0005;
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, this.scale * 10, 0, 2 * Math.PI, false);
        ctx.fillStyle = `rgba(255,255,255,${this.alpha})`;
        ctx.fill();
      }
    }

    initHeader();
    addListeners();

    return () => {
      window.removeEventListener('scroll', scrollCheck);
      window.removeEventListener('resize', resize);
    };
  }, []);
  return (
    <div className='mains'>

   <div id='forsnow'  className='relative' ref={largeHeaderRef}>
   <canvas id="demo-canvas" ref={canvasRef} style={{ backgroundColor: 'transparent' }}></canvas>

        <section id='bgcontact' className='absolute top-[0px] left-[0%] w-full bg-overlay bg-overlay-gradient pb-0 ng-section mb-0 '>
          <div  className="heads flex justify-center items-center z-50" style={{ height: '100vh' }}>
            <div className="relative w-2/3  ">
              <div className='w-full flex justify-center pt-[30px]'>
                <div className="h-20 w-16 bg-red-600 ">
                </div>
              </div>
              <div className="absolute top-4 pt-[30px] left-0 text-white text-2xl md:text-3xl text-center w-full flex justify-center tracking-wide  z-10 font-bold"  >
                <TypingAnimation text={content.home.slogan} speed={120} />
              </div>
            </div>
          </div>
        </section>
   </div>
   <div className='bg-zinc-800 w-full flex flex-col justify-center items-center'>
        <div className='w-11/12 p-12 '>
          <div className='flex flex-col justify-center items-center animate__animated animate__fadeInLeftBig animate__delay-1s'>
            <div className='text-xl sm:text-3xl pb-2 font-bold font-mono text-red-600'>{content.home.who}</div>
            <p className='text-lg sm:text-lg p-0 sm:p-8  text-center font-thin   text-white'>{content.home.whocon}</p>
  
          </div>
        </div>
   </div>
      <div className='w-full bg-zinc-900 p-12 pt-10 sm:p-5 flex '>
        <div className=' flex flex-col items-center justify-center'>
          <div className='text-2xl sm:text-3xl font-medium font-mono p-6 text-red-600 '>{content.home.productcategories}</div>
          <div className='w-full  md:w-11/12 flex flex-row flex-wrap justify-center item-center p-0 sm:p-2'>

          <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                  <img className='w-11/12 rounded-xl' src={bearing} />
                </div>
              </div>
              <div className='text-red-600 font-medium font-mono text-center text-xl' > {content.home.bearing}</div>
            </div>
            
            <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={seals} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.seals}</div>
            </div>
            <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={tool} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.tools}</div>
            </div>
             <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={filters} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.filters}</div>
            </div> <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={fittings} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.fittings}</div>
            </div> <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={hoses} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.hoses}</div>
            </div> <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={vbelt} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.vbelts}</div>
            </div> <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                <img className='w-11/12 rounded-xl' src={oring} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.oring}</div>
            </div> <div className=' pic flex flex-col items-center justify-center p-0 sm:p-2 pb-4 sm:pb-0 ease-in duration-300 ' >
              <div className='w-full p-2 sm:w-[250px] flex items-center justify-center  relative'>
                <div className="image-container flex items-center justify-center " >
                  <img className='w-11/12 rounded-xl' src={safety} />
                </div>
              </div>
              <div className='text-red-600 text-center font-medium font-mono text-xl' > {content.home.safety}</div>
            </div>
          </div>
          <Link className=' text-xl p-2 border-2  ease-in duration-300  m-3 font-medium font-mono  bg-red-600 rounded-xl  hover:bg-white text-white hover:text-red-600  hover:border-white border-red-600   ' to="/products">{content.home.explore}</Link>
        </div>
      </div>
      <div className='bg-zinc-800 w-full p-12'>
        <div className='flex flex-col justify-center items-center p-0 sm:p-2 animate__animated animate__zoomIn animate__delay-3s'>
          <div className='text-xl sm:text-3xl  font-bold font-mono pb-12 text-red-600 text-center'>{content.home.make}</div>


          <div className='w-11/12 flex flex-wrap justify-center text-center items-center '>
            <div className={`text-card w-full sm:w-5/12 md:w-[23%] sm:h-[220px] p-6 md:h-[290px] lg:h-[210px] m-2 md:m-1 flex flex-col bg-black text-white p-2 rounded-lg  ease-in duration-300 hover:bg-red-600 hover:scale-105 ${isRTL ? 'md:h-[150px]' : 'md:h-[200px]'}`}>
              <h3 className="font-bold text-lg flex justify-center pb-4 items-center ">{content.home.makehead1}</h3>
              <p className="text-card-text">{content.home.makecon1}</p>
            </div>
            <div className={`text-card w-full sm:w-5/12 md:w-[23%] sm:h-[220px] p-6 md:h-[290px] lg:h-[210px] m-2 md:m-1 flex flex-col bg-black text-white p-2 rounded-lg   ease-in duration-300  hover:bg-red-600 hover:scale-105 ${isRTL ? 'md:h-[150px]' : 'md:h-[200px]'}`}>
              <h3 className="font-bold text-lg  flex justify-center pb-4  items-center " >{content.home.makehead2}</h3>
              <p className="">{content.home.makecon2}</p>
            </div>
            <div className={`text-card w-full sm:w-5/12 md:w-[23%] sm:h-[220px] p-6 md:h-[290px] lg:h-[210px] m-2 md:m-1 flex flex-col bg-black text-white p-2  rounded-lg  ease-in duration-300  hover:bg-red-600  hover:scale-105 ${isRTL ? 'md:h-[150px]' : 'md:h-[200px]'}`}>
              <h3 className="font-bold text-lg  flex justify-center pb-4 items-center ">{content.home.makehead3}</h3>
              <p className="text-card-text">{content.home.makecon3}</p>
            </div>
            <div className={`text-card w-full sm:w-5/12 md:w-[23%] sm:h-[220px] p-6  md:h-[290px] lg:h-[210px]  m-2 md:m-1 flex flex-col bg-black text-white p-2 rounded-lg  ease-in duration-300  hover:bg-red-600 hover:scale-105 ${isRTL ? 'md:h-[150px]' : 'md:h-[200px]'}`}>
              <h3 className="font-bold text-lg  flex justify-center pb-4  items-center ">{content.home.makehead4}</h3>
              <p className="text-card-text">{content.home.makecon4}</p>
            </div>
          </div>



        </div>
      </div>

    </div>
  )
}

export default Home
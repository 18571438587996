import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/Insaf-Logo.png';

function Footer({ language, content }) {
  const isRTL = language === 'ar';

  return (
    <div>
      <footer className='bg-black text-white '>
        <div className='flex flex-col'>
          {/* first row */}
          <div className='flex flex-col p-10 justify-between'>
            {/* Tailwind classes for responsive flex-direction */}
            <div className={`flex flex-col mb-3 md:${isRTL ? 'flex-row-reverse' : 'flex-row'} ${isRTL ? ' justify-end' : 'justify-start'} md:justify-center ${isRTL ? ' pr-8' : 'pl-8'} `}>
          
              <div className={`text-lg w-full md:w-4/12 text-[#7f7e7e] flex justify-center items-center ${isRTL ? 'pl-5' : ''}`}>
                  <img className='w-10/12 sm:min-h-[60px] ' src={logo} alt='Logo'/>
                </div>

                <div className={`w-full md:w-3/12  flex flex-col  pl-5`}>
<div className={`p-1 flex flex-col justify-center ${isRTL ? 'text-end' : 'text-start'}`}>
                    <h2 className='font-extrabold text-base md:text-xl lg:text-xl p-1 tracking-widest text-red-600'>{content.footer.discover}</h2>
                    <Link to='/' className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.home}</Link>
                    <Link to='/About' className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.about}</Link>
                    <Link to='/products' className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.product}</Link>
                    <Link to='/contact' className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.contact}</Link>
</div>

</div>
<div className={`w-full md:w-3/12  flex flex-col pl-5 `}>
  <div className={`p-1 flex flex-col ${isRTL ? 'text-end' : 'text-start'}`}>
                    <h2 className='font-extrabold text-base md:text-xl lg:text-xl p-1 tracking-widest text-red-600 leading-4'>{content.footer.branch}</h2>
                    <div className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.Jeddah}</div>
                    <div className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.Makkah}</div>
                    <div className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.Riyadh}</div>
                    {/* <div className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.Yanbu}</div> */}
                    <div className='p-1 tracking-wide text-[#7f7e7e]'>{content.footer.Khamis}</div>

                  </div>
                  </div>
               

              {/* second col */}
              <div className={`w-full md:w-3/12  flex flex-col   pl-5`}>
<div className={`p-1 flex flex-col ${isRTL ? 'text-end' : 'text-start'}`}>
                    <h2 className='font-extrabold text-base md:text-xl lg:text-xl p-1 tracking-widest text-red-600 leading-4'>{content.footer.call}</h2>
                    <div><a href='tel:+0562563000' className='p-1 tracking-wide text-[#7f7e7e]'>0562563000</a></div>
                    <div><a href='tel:+0550637750' className='p-1 tracking-wide text-[#7f7e7e]'>0550637750</a></div>
{/*                     <div><a href='tel:+0503281836' className='p-1 tracking-wide text-[#7f7e7e]'>0503281836</a></div>
                 */}
                  <div className={`p-1   ${isRTL ? 'text-end' : 'text-start'}`}>
                  <h3 className='text-base md:text-xl lg:text-xl font-bold text-red-600'>{content.footer.mail} </h3>
                  <a href="mailto:sales@insaftech.com" className='text-lg text-[#7f7e7e]'>
    sales@insaftech.com
  </a>                </div>
</div>

</div>
            </div>
            <hr />
            {/* second row */}
            <div className='flex w-full justify-center'>
              <div className='-mb-5 text-center'>© 2024 All rights reserved By Insaftech<br />
                <a href='https://codepublicist.com/#' target="_blank" >Powered By <span className='hover:text-[#7f7e7e]'>Codepublicist</span> </a></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

import React from 'react';

const LanguageSelector = ({ onSelectLanguage }) => {
  const handleLanguageChange = (e) => {
    onSelectLanguage(e.target.value);
  };

  return (
    <div className="relative w-4/12 sm:w-full ms-0 sm:ms-5">
    <select
      className="block appearance-none w-full bg-red-800 text-white p-2 pl-4 pr-5 rounded-lg"
      onChange={handleLanguageChange}
    >
      <option value="en">EN</option>
      <option value="ar">AR</option>
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
      <svg
        className="w-6 h-6 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M10 12.586L6.707 9.293a1 1 0 011.414-1.414L10 10.758l2.879-2.879a1 1 0 111.414 1.414L10 12.586z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  </div>
  
  );
};

export default LanguageSelector;


